.react-modal-sheet-backdrop {
    box-shadow: 2px 2px #fff;
    background: rgba(0, 0, 0, 0.3) !important;
}
.react-modal-sheet-container {
    background-color: #121113 !important;
}
.react-modal-sheet-header {
    /* custom styles */
}
.react-modal-sheet-drag-indicator {
    /* custom styles */
}
.react-modal-sheet-content {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    margin-left: 3vw !important;
    margin-right: 3vw !important;
}