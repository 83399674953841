.FormRoot {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.FormField {
    margin-bottom: 15px;
}

.FormLabel {
    display: block;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
}

.FormMessage {
    font-size: 12px;
    color: #ff4d4f;
    margin-top: 2px;
}

.Input {
    width: 93%;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    transition: all 0.3s;
    appearance: none !important;
}

.Input:focus {
    outline: none;
    border-color: #fecf0a;
    box-shadow: 0 0 0 2px rgb(31, 31, 34);
    appearance: none !important;
}

.Input:hover {
    appearance: none !important;
}

.Textarea {
    width: 93%;
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    resize: vertical;
    min-height: 100px;
    transition: all 0.3s;
    appearance: none !important;
}

.Textarea:focus {
    outline: none;
    border-color: #fecf0a;
    box-shadow: 0 0 0 2px rgb(31, 31, 34);
    appearance: none !important;
}